import React from 'react';

import styles from './SuccessHeader.module.css';

import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';


const SuccessHeader = () => {
  return (
    <div className={styles.successHeader}>
      <Container type="mobile">
        <Title className={`${styles.successHeader__title} mb-3`}>You're in!</Title>
        <Text className={`${styles.successHeader__subtitle} text-center`}>Your account has been successfully upgraded. 🙂 <br /> </Text>
        <Text className={`${styles.successHeader__subtitle} ${styles.successHeader__login} text-center`}><a className={styles.bold} href="https://app.deadlinefunnel.com">Please click here to log in...</a></Text>

        <Image name="man_with_pc" className={styles.successHeader__image} />
      </Container>
    </div>
  )
};

export default SuccessHeader;