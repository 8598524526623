import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import SuccessHeader from '../components/slides/success/SuccessHeader';

const Success = () => {
  return (
    <Layout>
      <SEO
        title="Thank you! | Deadline Funnel"
         meta={[{
          name: 'robots',
          content: 'noindex',
        }]}
      />
      <SuccessHeader />
    </Layout>
  )
};

export default Success;
